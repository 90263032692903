<template>
  <div id="app">
    <div class="center">
      <router-view />
    </div>
  </div>
</template>
<script>
  export default {
    name: "App",
    components: {
    },
    created(){

    },
    methods:{

    }
  };
</script>

<style lang="scss">
@import "style/public";
@import "style/status";
.myTooltip{
  max-width: 50vw;
  max-height: 35vh;
  overflow-y: auto;
}
#app {
  width: 100%;
  height: 100%;
}
* {
  margin: 0;
  padding: 0;
}
html,
body {
  height: 100%;
}

.center {
  width: 100%;
  height: calc(100vh);
}
.tableBox {
  width: 100%;
  height: calc(100%);
  background: white;
  position: relative;
}
.fenye{
  position: absolute;
  bottom: 10px;
  right:10px;
  height: 32px;
  text-align: right;
}
.el-select-dropdown__item.selected{
  color: #FF5733 !important;
}
.el-loading-spinner i{
  color: #FF5733 !important;
}
/*分页bug解决*/
::v-deep .fenye .el-input__inner{
  padding: 0 15px !important;
}
::v-deep .scroll .el-table .el-table__cell{
  padding: 4px 0 !important;
}
.tableBox>.scroll{
  width: 100%;
  height: calc(100% - 52px);
  overflow: auto;
}
#nprogress .bar {
  background: rgba(255, 87, 51, 1) !important;
}
  .el-header{
    display: flex;
    align-items: center;
  }
  .form_li:hover{
    border-color: #FF5733 !important;
  }
 ::v-deep .el-dropdown-menu__item:focus, ::v-deep .el-dropdown-menu__item:hover{
      background-color: #fff2ec;
      color: #FF5733;
  }
  .el-cascader-node.in-active-path, .el-cascader-node.is-active, .el-cascader-node.is-selectable.in-checked-path{
    color: #FF5733 !important;
  }
  .el-button--primary{
    background-color: #FF5733 !important;
    border-color: #FF5733 !important;
  }
</style>
<!--表格公共bug-css-->
<style>
  /*//显示滚动条*/
.el-table__body-wrapper {
    overflow: auto !important;
    position: relative;
  }
</style>
<!--顶部导航栏公共类-->
<style>
  .el-menu {
    height: 100%;
  }
  .el-menu-item-group > ul {
    display: flex;
    flex-wrap: wrap;
  }
.el-submenu .el-menu-item {
    width: 100% !important;
    font-size: 14px;
    text-align: left;
    padding-left: 50px !important;
  }
  .el-menu-item:focus,
  .el-menu-item:hover {
    background-color: #fff !important;
  }
</style>
<!--左侧导航栏公共类-->
<style>
 .aside .el-submenu__title{
    font-size: 16px !important;
  }
 .aside .el-menu-item-group__title{
   padding: 0 !important;
 }
</style>
<style>
  .is-active{
    color:rgba(255, 87, 51, 1) !important;
    font-weight: 900;
  }
  .is-active .el-submenu__title{
    color:rgba(255, 87, 51, 1) !important;
    font-weight: 900;
  }

  .is-active .el-submenu__title i{
    color: rgba(255, 87, 51, 1);
    font-weight: 900;
  }
</style>
<!--  表格标题行末小bug  -->
<style>
    ::v-deep.el-table th.el-table__cell {
        background: #F5F6FA;
    }
.el-table__row{
  height: 55px;
  min-height: 55px;
}
</style>
<!--一级路由公共布局css-->
<style>
    .home {
        height: 100% !important;
    }
    .el-container {
        height: 100% !important;
    }
    .el-aside {
        height: 100% !important;
    }
    .hello .el-menu {
        padding-left: 238px !important;
    }

    .el-main {
        height: calc(100vh - 64px) !important;
        background: #f5f6fa !important;
    }
    .el-card__body,
    .el-main {
        padding: 0 !important;
    }
    .el-header {
        border-bottom: 1px solid #e6e6e6 !important;
    }
</style>
<!--四级路由公共css-->
<style>
 .el-menu--horizontal > .el-menu-item.is-active{
    border-bottom: 3px solid rgba(255, 87, 51, 1) !important;
  }
 .el-menu--horizontal > .el-menu-item{
   height: 100% !important;
 }
</style>
<!--表格列筛选样式-->
<style>
  .el-table .caret-wrapper{
    width:15px !important;
  }
</style>
<!--三级联动高度-->
<style>
   .el-cascader-menu__wrap{
    height: 250px !important;
  }
   .row-dialog .el-dialog{
     border-radius: 16px ;
   }
   .row-dialog  .el-dialog__title{
     display: flex;
     justify-content: center;
     align-items: center;
     font-weight: 600;
   }
   .row-dialog .el-form-item__label{
     font-weight: 600;
   }
   .row-dialog .row-dialog-footer{
     text-align: center;
   }
  .row-dialog .el-input__inner{
    line-height: 31px;
    height: 31px;
  }
   .row-dialog .el-input{
     width: auto;
   }
  .row-dialog  .el-input.is-active .el-input__inner, .row-dialog .el-input__inner:focus,.row-dialog .el-range-editor.is-active,.row-dialog .el-range-editor.is-active:hover, .row-dialog .el-select .is-focus{
     border-color: #FF5733 !important;
   }
  .row-dialog .el-date-editor .el-range-separator,.row-dialog .el-input__icon{
    height: auto;
  }
  .row-dialog .row-el-date .el-input__icon:nth-of-type(1){
    width: 0;
  }
   .row-dialog .el-date-editor.el-input, .row-dialog .el-date-editor.el-input__inner{
      width: 220px !important;
   }
</style>
