import request from "../util/require";

/**
 * 登录接口
 * @param obj
 * @returns {Promise<AxiosResponse<any>>}
 */
export function login(obj) {
    return   request({
        url:'/adminLogin/user',method:'post',data:obj
    })
}

/**
 * 获取环信登录所需信息
 * @param v
 * @returns {Promise<AxiosResponse<any>>}
 */
export function webIMInfo(v) {
    return   request({
        url:'/admin/get_user',method:'post',params: {userName:v}
    })
}
/**
 * 获取所有字典
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getDict(){//获取所有字典信息接口
    return request({
        url:'/dict/getDict',
        method:'GET',
    })
}

/**
 * 上传接口
 * @param file
 * @returns {Promise<AxiosResponse<any>>}
 */
export function uploadApi(file){//
    return request({
        url:'/upload/img',
        method:'post',
        data:file,
        headers:{
            'Content-Type': 'multipart/form-data'
        }
    })
}
