import moment from './js/moment';
import areaData from '../util/json/city.json'
import time from "echarts/src/scale/Time";
import Vue from 'vue'

export default {
    /**
     * @param timeStr 时间类数值，如时间戳
     * @param type 想要转换的时间类型，如'YYYY-MM-DD hh:mm:ss dddd'
     * @returns {*}
     */

    'moment': function (timeStr, type) {
        if (timeStr === '' || timeStr === null || timeStr === undefined) return '--'
        return moment(timeStr).format(String(type))
    },
    /**
     * 获取 end日期-start日期之间的 X个年X个月时长
     * @param end
     * @param start
     * @returns {{months: number, years: (*|number)}|null}
     */
    'momentDuration': function (end,start) {
        if(!start&&!end)return null
        let startDate = moment(start);
        let endDate = moment(end); // 不传end默认为当前时间
        // 获取年份和月份
        const years = endDate.diff(startDate, 'years');
        const months = endDate.diff(startDate, 'months') % 12;
        return {years,months}
    },
    /**
     * 只支持最近两天内的时间转换
     * @param timeStr 时间类数值，如时间戳
     * @returns {{api: {coord: function(*=, *=): *}, coordSys: {cellWidth: *, rangeInfo: {weeks: *, dayCount: *, start: *, end: *}, x: *, width: *, y: *, cellHeight: *, type: string, height: *}}|*}
     */

    'chatmoment': function (timeStr) {
        return moment(timeStr).calendar();
    },

    /**
     * 设置cookie 1、name 2、value 3、time(day)
     * @param cname
     * @param cvalue
     * @param exdays
     */

    'setCookie': function (cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toGMTString();
        document.cookie = cname + "=" + cvalue + "; " + expires;
    },

    /**
     * 获取cookie 1、name
     * @param cname
     * @returns {string}
     */

    'getCookie': function (cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i].trim();
            if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
        }
        return "";
    },

    /**
     * 根据类型名和id获取对应name
     * @param type
     * @param data
     * @returns {string|[]}
     */

    'dictSelectName': function (type, data) {
        if (!data) return '--'
        if (!(data instanceof Array)) {
            data = JSON.parse(data)
            if (!(data instanceof Array)) {
                data = [String(data)]
                if (!(data instanceof Array)) {
                    return '--'
                }
            }
        }
        const dict = JSON.parse(localStorage.getItem('LP_dict'))
        let arr = ''
        let name = []
        dict.detail.forEach(item => {
            if (item.name == type) {
                arr = item.detail
            }
        })
        const Result = (SArr, data) => {
            SArr.forEach(item => {
                if (data.includes(String(item.id))) {
                    name.push(item.name)
                } else if (item.detail && item.detail.length) {
                    Result(item.detail, data)
                }
            })
            return name
        }
        return arr && Result(arr, data) || '--'
    },

    /**
     * 根据类型名和id获取对应ID
     * @param type
     * @param data
     * @returns {string|[]}
     */

    'dictSelectId': function (type, data) {
        if (!data) return '--'
        if (!(data instanceof Array)) {
            data = JSON.parse(data)
            if (!(data instanceof Array)) {
                return '数据异常'
            }
        }
        const dict = JSON.parse(localStorage.getItem('LP_dict'))
        let arr = ''
        let id = []
        dict.detail.forEach(item => {
            if (item.name == type) {
                arr = item.detail
            }
        })
        const Result = (SArr, data) => {
            SArr.forEach(item => {
                if (data.includes(String(item.id))) {
                    id.push(item.id)
                } else if (item.detail && item.detail.length) {
                    Result(item.detail, data)
                }
            })
            return id
        }
        return Result(arr, data)
    },

    /**
     * 字典 递归 获取对应id
     * @param name
     * @returns {undefined|string}
     */

    'dictProp': function (name) {
        const dict = JSON.parse(localStorage.getItem('LP_dict'))
        let id = ''
        const Fun = (data, val) => {
            if (!val) return
            data.forEach(item => {
                if (item.name == val) {
                    id = item.id
                } else if (item.detail && item.detail.length) {
                    Fun(item.detail, val)
                }
            })
            return id
        }
        return Fun(dict.detail, name)
    },

    /**
     * 字典 递归 获取对应name
     * @param id
     * @returns {undefined|string}
     */

    'dictPropName': function (id) {
        if (!id) return '--'
        const dict = JSON.parse(localStorage.getItem('LP_dict'))
        let name = ''
        const Fun = (data, val) => {
            if (!val) return
            data.forEach(item => {
                if (item.id == val) {
                    name = item.name
                } else if (item.detail && item.detail.length) {
                    Fun(item.detail, val)
                }
            })
            return name && name.length ? name : '--'
        }
        return Fun(dict?.detail || [], id)
    },

    /**
     * 根据公司id获取公司名字
     * @param id
     * @returns {undefined|string}
     */

    'CompanyListPropName': function (id, companyList) {
        companyList = companyList || JSON.parse(localStorage.getItem('LP_companyList'))
        let name = ''
        const Fun = (data, val) => {
            if (!val) return
            data.forEach(item => {
                if (item.id == val) {
                    name = item.companyName
                }
            })
            return name
        }
        return Fun(companyList, id)
    },

    /**
     * 根据公司id获取公司地址
     * @param id
     * @returns {undefined|string}
     */

    'CompanyListPropArea': function (id, companyList) {
        companyList = companyList || JSON.parse(localStorage.getItem('LP_companyList'))
        let area = ''
        const Fun = (data, val) => {
            if (!val) return
            data.forEach(item => {
                if (item.id == val) {
                    area = item.address
                }
            })
            return area
        }
        return Fun(companyList, id)
    },

    /**
     * 字典 递归 获取对应detail对象
     * @param name
     * @returns {undefined|[]}
     */

    'dictPropOBJ': function (name) {
        const dict = JSON.parse(localStorage.getItem('LP_dict'))
        let arr = []
        const Fun = function (data, val) {
            if (!val) return []
            data.forEach(item => {
                if (item.name == val) {
                    arr = item.detail
                } else if (item.detail && item.detail.length) {
                    Fun(item.detail, val)
                }
            })
            return arr
        }
        return Fun(dict?.detail || [], name)
    },
    /** 此方法更有益性能优化
     * 传数组 需筛选的数据
     * @param {Object} arr
     * @param {Object} data
     */
    'dictSelectNamePro': function (arr, data) {
        if (!(data instanceof Array)) {
            data = JSON.parse(data)
            if (!(data instanceof Array)) {
                return '数据异常'
            }
        }
        let name = []
        const Result = (SArr, data) => {
            SArr.forEach(item => {
                if (data.includes(String(item.id))) {
                    name.push(item.name)
                } else if (item.detail && item.detail.length) {
                    Result(item.detail, data)
                }
            })
            return name
        }
        return Result(arr, data)
    },
    /**
     * 根据区adcode 获取省市区
     * @param adcode
     * @returns {[]}
     */

    'dictPropArea': function (adcode) {
        if (!adcode) return '--'
        let arr = []
        const Fun = function (data, id) {
            if (!id) return
            data.forEach(one => {
                if (one.adcode == id) {
                    arr = [one.title]
                }
                if (one.children && one.children.length) {
                    one.children.forEach(two => {
                        if (two.adcode == id) {
                            arr = [one.title, two.title]
                        }
                        if (two.children && two.children.length) {
                            two.children.forEach(three => {
                                if (three.adcode == id) {
                                    arr = [one.title, two.title, three.title]
                                }
                            })
                        }
                    })
                }
            })
            return arr
        }
        return Fun(areaData, adcode)
    },

    /**
     * 根据区name 获取省市区adcode
     * @param adName
     * @returns {undefined|[]}
     */

    'dictPropAreaId': function (adName) {
        let arr = []
        const Fun = function (data, name) {
            if (!name) return
            data.forEach(one => {
                if (one.title == name) {
                    arr = [one.adcode]
                }
                if (one.children && one.children.length) {
                    one.children.forEach(two => {
                        if (two.title == name) {
                            arr = [one.adcode, two.adcode]
                        }
                        if (two.children && two.children.length) {
                            two.children.forEach(three => {
                                if (three.title == name) {
                                    arr = [one.adcode, two.adcode, three.adcode]
                                }
                            })
                        }
                    })
                }
            })
            return arr
        }
        return Fun(areaData, adName)
    },
    /**
     * element 时间范围组件value值返回修改1
     * @param data
     * @returns {string[]}
     */
    timeValArrHandle(data) {
        if (data instanceof Array) {
            return [data[0] + ' 00:00:00', data[1] + ' 23:59:59']
        }
        return ''
    },
    /**
     * element 时间范围组件value值返回修改2
     * @param data
     * @returns {(*|number)[]}
     */
    timeValStrHandle(data) {
        if (data instanceof Array) {
            return [data[0], data[1] + 3600 * 1000 * 24 - 1000]
        }
        return ''
    },
    /**
     * 获取当前月第一天和最后一天的值
     * @returns {{firstDay: Date, lastDay: Date}}
     */
    getNowMonthDay() {
        const today = new Date();
        const firstDay = this.moment(new Date(today.getFullYear(), today.getMonth(), 1), 'YYYY-MM-DD');
        const lastDay = this.moment(new Date(today.getFullYear(), today.getMonth() + 1, 0), 'YYYY-MM-DD');
        return {firstDay, lastDay};
    }
}
