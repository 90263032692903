import Vue from 'vue'
import router from "@/router";
import {Loading, MessageBox} from "element-ui";
import sw from "element-ui/src/locale/lang/sw";
//全局状态颜色使用
Vue.prototype.$color=(num)=>{
    num=Number(num)
    switch (num) {
        case 2:
            return 'rgba(255, 141, 26, 1)'//已驳回
            break;
        case 1:
            return 'rgba(67, 207, 124, 1)'//已审核
            break;
        case 0:
            return 'rgba(255, 28, 28, 1)'//待审核
            break;
        default:return 'rgba(67, 207, 124, 1)'
    }
}
//全局timeout防抖控制
Vue.prototype.$timeout =null
//二次封装element 开启遮罩
Vue.prototype.$ELoading =(num,text='')=>{
    Loading.service({
        lock: true,
        text,
        spinner: 'el-icon-loading',
        background: `rgba(0, 0, 0, ${num?num:0.5})`
    });
}
//二次封装element 关闭遮罩
Vue.prototype.$EClose =()=>{
    Loading.service({}).close();
}
//二次封装element 删除警告弹窗  '确认删除后无法恢复，确认删除请点击确定即可删除xx。', '确认要删除此xx吗？'
Vue.prototype.$EConfirm=(message, title, type)=>{
    return MessageBox.confirm(message||'确认删除后无法恢复，确认删除请点击确定即可删除该条数据。', title||'确认删除该条数据吗？', {
        cancelButtonClass:'cancelButtonClass',
        confirmButtonClass:'confirmButtonClass',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        showClose:false,
        type: type||'error',
        customClass:'ptClass',
        center:true
    })
}
//请求参数空值过滤处理方法
Vue.prototype.$req=(data)=>{
    const req = JSON.parse(JSON.stringify(data)) //防止指针指向相同空间
    for (let reqKey in req) {
        if(req[reqKey]===''||req[reqKey]===null||req[reqKey]===undefined||JSON.stringify(req[reqKey])=='{}'||JSON.stringify(req[reqKey])=='[]'){
            delete req[reqKey]
        }else{
            if(req[reqKey] instanceof Object){
                for (const reqKeyKey in req[reqKey]) {
                    if(req[reqKey][reqKeyKey]===''||req[reqKey][reqKeyKey]===null||req[reqKey][reqKeyKey]===undefined||req[reqKey][reqKeyKey].length==0){
                        delete req[reqKey][reqKeyKey]
                    }
                    // if(JSON.stringify(req[reqKey])=='{}'){
                    //   delete req[reqKey]
                    // }
                }
            }
        }
    }
    return req
}
//默认头像
Vue.prototype.$defaultHeadUrl=()=>{
    return 'https://testfile.youmijob.com/images/20231024/f485a0908eac2d68101ab23d0f70a0b.png'
}
//全局timeout防抖控制对象
Vue.prototype.$timeout =null
//全局timeout防抖控制函数
Vue.prototype.$timeoutHandle =(fun)=>{
    if(Vue.prototype.$timeout)return
    fun()
    Vue.prototype.$timeout=setTimeout(()=>{
        Vue.prototype.$timeout=null
    },600)
}
//判断非空取数字方法
Vue.prototype.$ifNum=(data,decimalLength,type)=>{
    if(data!==''&&data!==undefined&&data!==null){
        if(decimalLength){
            if(type==='toLocaleString'){
                return Number(data).toFixed(decimalLength).toLocaleString()
            }
            return Number(data).toFixed(decimalLength)
        }else{
            if(type==='toLocaleString'){
                return Number(data).toLocaleString()
            }
            return data
        }
    }else{
        return '--'
    }
}
