import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
Vue.use(VueRouter);
import HF from  '../util/myApi'
import md5 from "../util/js/md5";
import {login} from "../api/public";
import {Message} from "element-ui";
const RouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (to) {
  return RouterPush.call(this, to).catch(err => err)
}
const RouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (to) {
  return RouterReplace.call(this, to).catch(err => err)
}
const routes = [
  // 工作台
  {
    path: "/",
    redirect: "/worktable",
  },
  //登录页
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login"),
    meta:{
      title:'礼聘-登录'
    }
  },
  {
    path: '*',
    name:'404',
    component:()=>import('../views/notFount'),
    meta: {
      title: '404'
    }
  },
];
const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});
let arr = []
let allRoutes = []
if(localStorage.getItem('LP_allRouter')){
  allRoutes = JSON.parse(localStorage.getItem('LP_allRouter'))
}

if(allRoutes.length){
  allRoutes.forEach(item=>{
    let a = {
      path:item.path,
      name: item.name,
      component:resolve => require(['../views'+item.component], resolve),
      meta:item.meta?JSON.parse(JSON.stringify(item.meta)):{},
      children:[]
    }
    if(JSON.parse(JSON.stringify(item.meta)).redirect){
      a.redirect=JSON.parse(JSON.stringify(item.meta)).redirect
    }
    arr.push(a)
  })
  allRoutes.forEach((item,i)=>{
    if(item.children&&item.children.length){
      item.children.forEach(vitem=>{
        let a = {
          path:vitem.path,
          name: vitem.name,
          component:resolve => require(['../views'+vitem.component], resolve),
          meta:vitem.meta?JSON.parse(JSON.stringify(vitem.meta)):{},
          children:[]
        }
        if(JSON.parse(JSON.stringify(vitem.meta)).redirect){
          a.redirect=JSON.parse(JSON.stringify(vitem.meta)).redirect
        }
        arr[i].children.push(a)
      })
    }
  })
  allRoutes.forEach((item,i)=>{
    if(item.children&&item.children.length){
      item.children.forEach((vitem,vi)=>{
        if(vitem.children&&vitem.children.length){
          vitem.children.forEach(witem=>{
            let a = {
              path:witem.path,
              name: witem.name,
              component:resolve => require(['../views'+witem.component], resolve),
              meta:witem.meta?JSON.parse(JSON.stringify(witem.meta)):{},
              children:[]
            }
            arr[i].children[vi].children.push(a)
          })
        }
      })
    }
  })
  arr.forEach(item=>{
    router.addRoute(item.name,item)
  })
}
const whiteList=['/login']
router.beforeEach((to, from, next) => {
  //出现进度条
  NProgress.start()
  //登录时可能需要的一个操作赋值
  if(from.name!='404'){
    to.meta.from=from.path
  }else{
    to.meta.from=''
  }
  //记住密码 自动登录 调用登录接口更新token
  // if(HF.getCookie('LP_ACTSAVE')&&(!sessionStorage.getItem('LP_TOKEN'))){
  //   const {username,password}  = JSON.parse(HF.getCookie('LP_ACTSAVE'))
  //   const user = {username,password:md5.hexMD5(password)}
  //   login(user).then(res=>{
  //     if(res.code==1000){
  //       if(!res.data) return Message.error('账号异常')
  //       localStorage.setItem('LP_loginInfo',JSON.stringify(res.data))
  //       sessionStorage.setItem('LP_TOKEN',JSON.stringify(res.data.token))
  //       next()
  //     }else{
  //       Message.error('登录失败')
  //       next('/login')
  //     }
  //   })
  // }else{
  //   if(whiteList.includes(to.fullPath)){
  //     next()
  //     // if(sessionStorage.getItem('LP_TOKEN')){
  //     //   return next(to.fullPath)
  //     // }else{
  //     //   next()
  //     // }
  //   }else{
  //     if(sessionStorage.getItem('LP_TOKEN')){
  //       return  next()
  //     }else{
  //       next('/login')
  //       NProgress.done()
  //     }
  //   }
  // }
  if(whiteList.includes(to.fullPath)){
    next()
  }else{
    if(sessionStorage.getItem('LP_TOKEN')){
      return  next()
    }else{
      next('/login')
      NProgress.done()
    }
  }
})

router.afterEach((to,from)=>{
  document.title=to.meta.title
  NProgress.done()
})
export default router;
