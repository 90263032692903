import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
import {resetRouter} from '../router/initRoutes'
import country from '../util/json/city.json'
country.shift()
import HF from '../util/myApi'
import routerJSON from '@/router/index.json'
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    Home:{
      NumChange:true,
      EChartsFlag:true
    },
    regionData:country,//静态省市区数据
    loginInfo:null,
    userRouter:null,
    webIMMsgHistoryNum:10,
    justLogin:false,//是否是刚登录状态，用于组件Header提示用户完善个人信息功能
    codeTime:0,//验证码倒计时
    //当前分页
    paging: {
      size: 20,
      no: 1
    },
  },
  mutations: {
    codeTimeHandel(state,v){
      state.codeTime=v
      let intTime = setInterval(()=>{
        state.codeTime--
        if(!state.codeTime){
          return clearInterval(intTime)
        }
      },1000)

    },
    //刚刚登录
    justLoginState(state){
      state.justLogin=true
    },
    //退出登录
    tokenTimeOut(state){
      resetRouter()//重置路由信息--清除旧路由
      state.loginInfo=''
      state.userRouter=null
      localStorage.removeItem('LP_loginInfo')
      sessionStorage.removeItem('LP_TOKEN')
      HF.setCookie('LP_ACTSAVE','',0)
      localStorage.removeItem('LP_userTokenTime')
      localStorage.removeItem('LP_allRouter')
      localStorage.removeItem('LP_dict')
      localStorage.removeItem('LP_postClass')
      router.replace('/login')
      // location.reload()
    },
    changeEChartsFlag(state){
      state.Home.EChartsFlag=!state.Home.EChartsFlag
    },
    upRegionData(state,data){
      state.regionData=data
    },
    //动态||静态路由
    loginInfoSave(state,data){
      state.loginInfo=data
      // let allRoutes = data.auth
      let allRoutes = routerJSON
      let arr = []
      if(allRoutes){
        allRoutes.forEach(item=>{
          let a = {
            path:item.path,
            name: item.name,
            component:resolve => require(['../views'+item.component], resolve),
            meta:item.meta?JSON.parse(JSON.stringify(item.meta)):{},
            children:[]
          }
          if(JSON.parse(JSON.stringify(item.meta)).redirect){
            a.redirect=JSON.parse(JSON.stringify(item.meta)).redirect
          }
          arr.push(a)
        })
        allRoutes.forEach((item,i)=>{
          if(item.children&&item.children.length){
            item.children.forEach(vitem=>{
              let a = {
                path:vitem.path,
                name: vitem.name,
                component:resolve => require(['../views'+vitem.component], resolve),
                meta:vitem.meta?JSON.parse(JSON.stringify(vitem.meta)):{},
                children:[]
              }
              if(JSON.parse(JSON.stringify(vitem.meta)).redirect){
                a.redirect=JSON.parse(JSON.stringify(vitem.meta)).redirect
              }
              arr[i].children.push(a)
            })
          }
        })
        allRoutes.forEach((item,i)=>{
          if(item.children&&item.children.length){
            item.children.forEach((vitem,vi)=>{
              if(vitem.children&&vitem.children.length){
                vitem.children.forEach(witem=>{
                  let a = {
                    path:witem.path,
                    name: witem.name,
                    component:resolve => require(['../views'+witem.component], resolve),
                    meta:witem.meta?JSON.parse(JSON.stringify(witem.meta)):{},
                    children:[]
                  }
                  arr[i].children[vi].children.push(a)
                })
              }
            })
          }
        })
        state.userRouter=arr
        arr.forEach(item=>{
          router.addRoute(item.name,item)
        })
        localStorage.setItem('LP_allRouter',JSON.stringify(allRoutes)) // vuex完成动态路由添加 标记
      }
    },
    loginInfoClear(state){
      state.loginInfo=null
    },
    //记录table分页公共状态 场景：导出表格
    pagingState(state, v) {
      state.paging = v
    },
  },
  actions: {

  },
  modules: {},
});
