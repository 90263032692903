import axios from 'axios'
import store from '../store/index'
import {Message,Loading} from "element-ui";
axios.defaults.baseURL='/api'
axios.interceptors.response.use(res =>{
    if(!window.navigator.onLine){
        Loading.service({}).close()//全局关闭加载遮罩
        Message.error('网络错误')
        return false
    }
    if(res&&res.status==200){
        if(res&&res.headers&&res.headers.token){
            sessionStorage.setItem('LP_TOKEN',JSON.stringify(res.headers.token))
        }
        if(res&&res.data&&res.data.code==1000){
            return res.data
        }else{
            //根据需求可加特殊处理
            return res.data
        }
    }else{
        return false
    }
},err=>{
    Loading.service({
        lock: true,
        spinner: 'el-icon-loading',
        background: `rgba(0, 0, 0, 0.5)`
    })
    Loading.service({}).close()//全局关闭加载遮罩
    if(!window.navigator.onLine){
        Message.error('网络错误')
        return false
    }
    if(err.response&&err.response.headers&&err.response.headers.token){
        sessionStorage.setItem('LP_TOKEN',JSON.stringify(err.response.headers.token))
    }
    if(err.response&&err.response.data&&(err.response.data.status==888||err.response.data.status==999)){
        Message.error('身份过期，请重新登录')
        return store.commit('tokenTimeOut')//退出登录
    }
    if (err.response&&err.response.status===400) {
        Message.error('请求错误')
    } else if (err.response&&err.response.data&&JSON.stringify(err.response.data).includes('ENOTFOUND')) {
        Message.error('网络错误')
    } else {
        Message.error('服务器异常')
    }
    return false
})
const request = function ({url,method,data,params}) {
    let TOKEN
    if(localStorage.getItem('LP_loginInfo')){
        if(sessionStorage.getItem('LP_TOKEN')||JSON.parse(localStorage.getItem('LP_loginInfo')).token){
            TOKEN = sessionStorage.getItem('LP_TOKEN')||JSON.parse(localStorage.getItem('LP_loginInfo')).token
            TOKEN=TOKEN.substring(1,TOKEN.length-1)//去除双引号
        }
    }
    if(TOKEN){
        // console.log('开启全局请求头')
        axios.defaults.headers.common['token']=TOKEN;
    }else{
        delete axios.defaults.headers.common['token'];
    }
    return axios({
        url,
        method,
        params,
        data,
        timeout:30000
    })
}

export default request
