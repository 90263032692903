//去除touch事件谷歌提示
(function () {
  if (typeof EventTarget !== "undefined") {
    let func = EventTarget.prototype.addEventListener;
    EventTarget.prototype.addEventListener = function (type, fn, capture) {
      if(!this){
        return
      }
      this.func = func;
      if (typeof capture !== "boolean") {
        capture = capture || {};
        capture.passive = false;
      }
      this.func(type, fn, capture);
    };
  };
}());

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/util/prototype"//Vue prototype自定义封装方法
Vue.use(ElementUI);
import echarts from "echarts";
import china from 'echarts/map/json/china.json'
echarts.registerMap('china', china);
Vue.prototype.$echarts = echarts
import axios from 'axios';
import VueAxios from 'vue-axios';
Vue.use(VueAxios,axios);
import directives from '../src/util/directives'
Vue.use(directives)
import myApi from "./util/myApi";
Vue.prototype.$HF = myApi

// 初始化客户端
Vue.component('MyTable',()=>import('@/components/public/MyTable'))
Vue.component('MyUpload',()=>import('@/components/public/MyUpload'))
Vue.component('MySearch',()=>import('@/components/public/SearchBtn'))
Vue.component('MyReset',()=>import('@/components/public/ResetBtn'))
Vue.component('MySubmit',()=>import('@/components/public/SubmitBtn'))
Vue.component('MyCancel',()=>import('@/components/public/CancelBtn'))
Vue.component('MyCascader',()=>import('@/components/public/filter/Cascader'))
Vue.component('MyInput',()=>import('@/components/public/filter/Input'))
Vue.component('MySelect',()=>import('@/components/public/filter/Select'))
Vue.component('MyDatePicker',()=>import('@/components/public/filter/DatePicker'))
Vue.component('solidArcBtn',()=>import('@/components/public/solidArcBtn'))//公共实心弧形按钮
Vue.component('hollowArcBtn',()=>import('@/components/public/hollowArcBtn'))//公共空心弧形按钮
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
